















































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab3Q8',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswers: [],
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: 'To remove salts', value: 'removeSalts'},
        {text: 'Because it is inexpensive', value: 'inexpensive'},
        {text: 'To remove inorganic impurities', value: 'removeImpurities'},
        {
          text: 'Because it is good to separate neutral organic components',
          value: 'separateComponents',
        },
        {text: 'Because it is more environmentally friendly', value: 'environment'},
      ],
      optionsFr: [
        {text: "Parce que c'est peu dispendieux", value: 'inexpensive'},
        {
          text: "Parce que c'est efficace pour séparer les composés organiques neutres",
          value: 'separateComponents',
        },
        {text: "Parce que c'est meilleur pour l'environnement", value: 'environment'},
        {text: 'Pour retirer les sels', value: 'removeSalts'},
        {text: 'Pour retirer les impuretés inorganiques', value: 'removeImpurities'},
      ],
    };
  },
};
